:root {
  --black: #000c14;
  --light-purple: #9A8F97;
  --mid-purple: #513B56;
  --dark-purple: #2D1E2F;
  --dark-purple-muted: #342E37;
  --true-purple: #462255;
  --light-tan: #C3BABA;
  --neutral-tan: #E9E3E6;
  --light-grey-green: #B2B2B2;
  --mid-green: #31493C;
  --accent-brown: #B2B2B2;
}


body {
  margin: 0;
  font-family: 'Castoro Titling', cursive;
  font-family: 'Karma', serif;
  font-family: 'Libre Caslon Display', serif;
  font-family: 'Playfair Display', serif;;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

