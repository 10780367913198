/* Homepage.css */
:root{
  --red: rgb(233,30,99);
  --purple: rgb(206,147,216);
  --orange: rgb(255,165,0);
  --green: rgb(102,187,106);

}

html {
  overflow: hidden !important;
}

/* font-family: 'Castoro Titling', serif;
font-family: 'Karma', serif;
font-family: 'Libre Caslon Display', serif;
*/

body {
  font-family: 'Playfair Display', serif !important; 
}

.homepage {
  z-index: 1;
  background-image: url("./img/inpiration/streetlight.png");
  background-size: cover;
  height: 100vh;
}

.nav-bar {
  position: sticky;
  z-index: 999;
  /* display: flex; */
  /* justify-content: space-between; */
  /* align-items: center; */
  background-color: transparent !important;
  box-shadow: none !important;
  padding: 10px;
  background: linear-gradient(to bottom, black 30%, transparent);
}

@media (max-width: 600px) {
  .nav-bar {
    background: black;
  }
}

.hamburger {
  background: none;
  border: none;
  color: #a4c5e5;
  font-size: 28px;
  font-weight: bold;
  padding: 0 20px;
}

.logo {
  /* padding-left: 20px; */
  perspective: 1000px;
}

.logo img {
  display: block;
  width: 50px;
  height: 50px;
  transition: transform 0.6s;
}

.logo:hover img {
  transform: rotateY(180deg);
}

.main-logo {
  z-index: 1 !important;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 8000ms;
  width: 400px;
  height: 300px;
  background-image: url("./img/logo/logo-04.png");
  background-size: contain;
  background-repeat: no-repeat;
}

.fade-in {
  opacity: 1;
}

.arrow-up {
  z-index: 150 !important;
  animation: hoverArrow 2s infinite;

  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);

  width: 40px;
  height: 40px;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;

  background: #22222276;
  color: #a4c5e5;

  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.arrow-up:hover {
  color: #a4c5e5;
}

.hover-arrow {
  z-index: 150 !important;
  animation: hoverArrow 2s infinite;

  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);

  width: 40px;
  height: 40px;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;

  background: #22222276;
  /* color: rgb(216,27,96); */
  color: #a4c5e5;

  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.hover-arrow:hover {
  color: #a4c5e5;
}

@keyframes hoverArrow {
  0% {
    transform: translate(-50%, 20px);
  }
  50% {
    transform: translate(-50%, 30px);
  }
  100% {
    transform: translate(-50%, 20px);
  }
}

/* Panel styles */
.panel1 {
  position: absolute;
  z-index: 100;
  width: 100%;
  height: 90vh;
  overflow: hidden;
  background-color: black;
}

.panel2 {
  position: absolute;
  z-index: 100;
  width: 100%;
  height: 90vh;
  overflow: hidden;
  background-color: rgb(66, 77, 81);
}

.mail-icon {
  /* color: rgb(216,27,96); */
  color: #a4c5e5;
  padding-inline: 20px;
  font-size: large;
}

.mail-icon:hover {
  color:white; 
  transform: rotate(10deg);
}

.phone-icon {
  color: #a4c5e5;
}

.menu-icon {
  padding-inline: 10px;
}

.menu-icon:hover {
  /* color: rgb(216,27,96); */
  /* transform: rotate(-15deg) */
}

/* .menu-backdrop {
  background-color: rgba(157, 157, 157, 0.297) !important;
  transition: background-color 2s linear
} */

.menu {
  /* background-color: rgb(21, 29, 31); */
  background-color: rgb(39, 50, 53);

}

.drawer-top {
  margin: auto;
  margin-top: 10px;
  display: flex;
  width: 80%;
  height: 50px;
  justify-content: center;
  padding: 10px;
}

.drawer-top img {
  width: 50px;
}

.drawer-bottom {
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 10px;
  height: 100%;
  gap: 10px;
}


